import { buildCollection, buildProperty, EntityReference } from "firecms";
import { commonCallbacks } from "../commonCallbacks";
import { getCurrentUser } from "../../firebase/firebase";
import { SocialLinksField } from "../../containers/SocialLinksField.container";
import { IMAGE_COMPRESSION_MAX_WIDTH } from "./events.constants";

type tabs = {
  name: string;
  content: string;
  slug: string;
};

type organiserOrSponsor = {
  name: string;
  website: string;
  description: string;
  logo: string;
  type: string;
};

type hackathon = {
  calyxId: number;
  banner: string;
  landingPageBanner: string;
  landingPageMobileBanner: string;
  sponsors: Array<organiserOrSponsor>;
  organisers: Array<organiserOrSponsor>;
  tabs: Array<tabs>;
  community: EntityReference;
  communityCalyxId: number;
  user: string;
  files: Array<string>;
  createdAt: Date;
  updatedAt: Date;
};

export const hackathonCollection = buildCollection<hackathon>({
  name: "Contests",
  singularName: "Contest",
  path: "cms_events_hackathon",
  icon: "EmojiEvents",
  permissions: () => ({
    edit: true,
    create: false,
    delete: false,
  }),
  properties: {
    calyxId: {
      name: "Calyx Contest ID",
      disabled: true,
      dataType: "number",
    },
    banner: {
      name: "Banner Image (1:1 Aspect Ratio)",
      description:
        "Provide an image with resolution of 256x256 for better clarity.",
      dataType: "string",
      storage: {
        imageCompression: {
          maxWidth: IMAGE_COMPRESSION_MAX_WIDTH.bannerLogo,
        },
        storagePath: "cms/events/hackathon/",
        acceptedFiles: ["image/*"],
      },
    },
    landingPageBanner: {
      name: "Landing Page Banner (4:1 Aspect Ratio)",
      description:
        "Provide an image with minimum resolution of 1920x480 for better clarity.",
      dataType: "string",
      storage: {
        imageCompression: {
          maxWidth: IMAGE_COMPRESSION_MAX_WIDTH.landingPageBannerDesktop,
        },
        storagePath: "cms/events/hackathon/",
        acceptedFiles: ["image/*"],
      },
    },
    landingPageMobileBanner: {
      name: "Landing Page Mobile Banner (2:1 Aspect Ratio)",
      description:
        "Provide an image with resolution of 768x384 for better clarity.",
      dataType: "string",
      storage: {
        imageCompression: {
          maxWidth: IMAGE_COMPRESSION_MAX_WIDTH.landingPageBannerMobile,
        },
        storagePath: "cms/events/hackathon/",
        acceptedFiles: ["image/*"],
      },
    },
    sponsors: buildProperty({
      dataType: "array",
      name: "Sponsors",
      of: {
        dataType: "map",
        properties: {
          name: {
            name: "Name",
            dataType: "string",
            validation: { required: true },
          },
          website: {
            dataType: "string",
            name: "Website",
            url: true,
          },
          description: {
            name: "Description",
            dataType: "string",
          },
          logo: {
            name: "Logo (1:1 Aspect Ratio)",
            dataType: "string",
            description:
              "Provide an image with resolution of 192x192 for better clarity.",
            storage: {
              imageCompression: {
                maxWidth: IMAGE_COMPRESSION_MAX_WIDTH.sponsorLogo,
              },
              storagePath: "cms/events/hackathon/",
              acceptedFiles: ["image/*"],
            },
          },
          type: {
            name: "Type",
            validation: { required: true },
            dataType: "string",
            enumValues: {
              individual: "Individual",
              organisation: "Organisation",
            },
          },
          socialLinks: buildProperty({
            dataType: "array",
            name: "Social Links",
            of: {
              dataType: "map",
              properties: {
                url: {
                  name: "Url",
                  dataType: "string",
                },
                type: {
                  name: "Type",
                  dataType: "number",
                  Field: SocialLinksField
                }
              }
            },
          }),
          phones: buildProperty({
            dataType: "array",
            name: "Phone Number Details",
            of:{
              dataType: "map",
              properties: {
                countryCode: {
                  name: "Country Code",
                  dataType: "string",
                },
                phoneNumber: {
                  name: "Phone Number",
                  dataType: "string",
                }
              }
            },
          }),
        },
      },
    }),
    organisers: buildProperty({
      dataType: "array",
      name: "Organisers",
      of: {
        dataType: "map",
        properties: {
          name: {
            name: "Name",
            dataType: "string",
            validation: { required: true },
          },
          website: {
            dataType: "string",
            name: "Website",
            url: true,
          },
          description: {
            name: "Description",
            dataType: "string",
          },
          logo: {
            name: "Logo (1:1 Aspect Ratio)",
            dataType: "string",
            description: 
              "Provide an image with resolution of 192x192 for better clarity.",
            storage: {
              imageCompression: {
                maxWidth: IMAGE_COMPRESSION_MAX_WIDTH.organiserLogo,
              },
              storagePath: "cms/events/hackathon/",
              acceptedFiles: ["image/*"],
            },
          },
          type: {
            name: "Type",
            validation: { required: true },
            dataType: "string",
            enumValues: {
              individual: "Individual",
              organisation: "Organisation",
            },
          },
          socialLinks: buildProperty({
            dataType: "array",
            name: "Social Links",
            of:{
              dataType: "map",
              properties: {
                url: {
                  name: "Url",
                  dataType: "string",
                },
                type: {
                  name: "Type",
                  dataType: "number",
                  Field: SocialLinksField
                }
              }
            },
          }),
          phones: buildProperty({
            dataType: "array",
            name: "Phone Number Details",
            of:{
              dataType: "map",
              properties: {
                countryCode: {
                  name: "Country Code",
                  dataType: "string",
                },
                phoneNumber: {
                  name: "Phone Number",
                  dataType: "string",
                }
              }
            },
          }),
        },
      },
    }),
    tabs: buildProperty({
      dataType: "array",
      name: "Tabs",
      of: {
        dataType: "map",
        properties: {
          name: {
            name: "Tab Name",
            dataType: "string",
            validation: { required: true },
          },
          content: {
            name: "Content",
            dataType: "string",
            markdown: true,
            validation: { required: true },
          },
          slug: {
            name: "Slug",
            dataType: "string",
            validation: { required: true },
          },
        },
      },
      expanded: true,
    }),
    community: {
      dataType: "reference",
      name: "Community",
      path: "communities",
      validation: { required: true },
    },
    communityCalyxId: {
      name: "Community Calyx Id",
      dataType: "number",
      disabled: {
        hidden: true,
      },
    },
    files: {
      name: "Files",
      dataType: "array",
      of: {
        dataType: "string",
        storage: {
          storagePath: "cms/events/hackathon/",
        },
      },
    },
    user: () => {
      const user = getCurrentUser();
      return {
        name: "Created  or Updated By User",
        dataType: "string",
        disabled: {
          hidden: true,
        },
        defaultValue: user?.email?.toString(),
      };
    },
    createdAt: {
      name: "Created at",
      dataType: "date",
      disabled: true,
    },
    updatedAt: {
      name: "Updated at",
      dataType: "date",
      disabled: true,
    },
  },
  group: "Events",
  callbacks: commonCallbacks,
});
